<template>
  <div>
    <v-card>
      <v-card-title>Users passwords</v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Roles</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.username }}</td>
            <td>{{ user.roles.join(', ') }}</td>
            <td>
              <v-btn depressed color="blue" dark small @click="changePassword.data = changePasswordBase(); changePassword.data.id = user.id; changePassword.show = true">change password</v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="changePassword.show" max-width="400px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Change password</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="changePassword.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="changePassword.data.newPass" type="password" label="New password" />
          <v-text-field v-model="changePassword.data.repeatPass" type="password" label="Repeat new password" />
          <v-btn @click="changePasswordReq">Change</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import functions from "../mixins/functions";
import globals from "../mixins/globals";
import axios from "axios";

export default {
  name: "Settings",
  data () {
    return {
      users: [],
      changePassword: {
        show: false,
        data: this.changePasswordBase()
      }
    }
  },
  methods: {
    changePasswordReq () {
      axios.post(this.globals.apiUrl + '/users/changePassword', this.changePassword.data)
      .then(() => {
        this.changePassword.show = false
        this.notyf('Password changed', 'success')
      })
      .catch((reason) => {
        this.notyf(reason.response.data.message, 'error')
      })
    },
    changePasswordBase () {
      return {
        id: '',
        newPass: '',
        repeatPass: '',
      }
    }
  },
  mixins: [functions, globals],
  created() {
    this.$store.commit('showLoading');
    axios.get(this.globals.apiUrl + '/users/list').then(res => {
      this.users = res.data
      this.$store.commit('hideLoading');
    })
  }
}
</script>

<style scoped>

</style>
